import { PageProps } from 'gatsby';
import { SchemaData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Schema from '~/components/List/Item/Schema';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import params from '~/params/schema.json';
import TemplateAdmin from '~/templates/admin';
import requireUser, { UserProps } from '~/utils/requireUser';

const AdminSchemas: FC<PageProps & UserProps> = ({ location, user }) => {
  const { t } = useTranslation();

  return (
    <TemplateAdmin>
      <CMSView>
        <List
          itemList={Schema}
          model={new SchemaData({ params })}
          search={location.search}
          title={t('schemas.title')}
          user={user}
        />
      </CMSView>
    </TemplateAdmin>
  );
};

export default requireUser(AdminSchemas, 'admin');
